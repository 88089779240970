import './Footer.css'
import MavLogo from '../Mav-Logo.svg'
import { ShowLiveChat } from '../2 - Top Section/TopSection'
import { ScrollTo } from '../2 - Top Section/TopSection'


const Footer = () => {

    const ShowPop = (id) => {
        document.getElementById(id).classList.remove('Hidden')
    }

    return(
        <div id="Footer">
            <div id="Footer-Container">
                <div id="Footer-Logo-Area">
                    <img id="Footer-Logo" src={MavLogo} />
                    <p id="Footer-Logo-Text">We'll keep you in the loop, every step of the way. Going above & beyond to ensure you recieve the store you've always wanted. Let's get started now!</p>
                    <a href="https://calendly.com/mavric/getstarted" target="_blank"  id="Footer-Button">Book a call</a>
                </div>
                <div id="Right-Footer-Area">
                    <div className="Right-Footer-Content">
                        <h2 className="Right-Footer-Headline">Browse</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => {ScrollTo('Top-Section', 0)}}>Home</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => {ScrollTo('Blank-Canvas', 50)}}>Features</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => {ScrollTo('Speed', 0)}}>Speed test</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => {ScrollTo('Bonus', 0)}}>UGC content</h2>
                    </div>
                    <div className="Right-Footer-Content">
                        <h2 className="Right-Footer-Headline">Help</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => {ScrollTo('FAQs', 50)}}>FAQs</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => ShowLiveChat()}>Live chat</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => ShowLiveChat()}>Contact us</h2>
                        <a href="https://calendly.com/mavric/getstarted" target="_blank"  className="Right-Footer-Sub-Text">Book a call</a>
                    </div>
                    <div className="Right-Footer-Content">
                        <h2 className="Right-Footer-Headline">Extra Links</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => ShowPop('Terms-Popup')}>Terms & service</h2>
                        <h2 className="Right-Footer-Sub-Text"  onClick={() => ShowPop('Privacy-Popup')}>Privacy policy</h2>
                        <h2 className="Right-Footer-Sub-Text" onClick={() => ShowPop('Refund-Popup')}>Refund policy</h2>
                    </div>

                </div>
            </div>
            <div id="Footer-Disclaimer">
                <p id="Footer-Disclaimer-Text">© Copyright 2021, All Rights Reserved</p>
                <div id="Footer-Disclaimer-Menu">
                    <p id="Footer-Disclaimer-Text-1" onClick={() => ShowPop('Terms-Popup')}>Terms</p>
                    <p id="Footer-Disclaimer-Text-1" onClick={() => ShowPop('Privacy-Popup')}>Privacy</p>
                    <p id="Footer-Disclaimer-Text-1" onClick={() => ShowPop('Privacy-Popup')}>Cookie preferences</p>
                    <p id="Footer-Disclaimer-Text-1" onClick={() => ShowLiveChat()}>Contact</p>
                    <p id="Footer-Disclaimer-Text-1">© Copyright 2023, All Rights Reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer