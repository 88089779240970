import './PixelPerfect.css'
import PP1 from './PP-1.png'
import PP2 from './PP-2.png'

import MB1 from './MB1.png'
import MB2 from './MB2.png'
import MB3 from './MB3.png'
import MB4 from './MB4.png'
import MB5 from './MB5.png'
import MB6 from './MB6.png'

import React, { useEffect } from 'react'

const PixelPerfect = () => {

  function PixelImageAnimation (element) {
    document.addEventListener('scroll', function() {

        var start = element.getBoundingClientRect().top - window.innerHeight;
        var end = element.getBoundingClientRect().top - ((window.innerHeight / 100) * 60)
        console.log(start, end)
        var distance = Math.abs(start - end);
        var percentageVisible = (start / distance) * -1;
        var percentage = Math.abs(percentageVisible);
        document.body.style.setProperty( "--pixel-image", percentage )
      
    });
  }

  function PixelTextAnimation (element) {
    document.addEventListener('scroll', function() {

        var start = element.getBoundingClientRect().top - window.innerHeight;
        var end = element.getBoundingClientRect().top - ((window.innerHeight / 100) * 60)
        console.log(start, end)
        var distance = Math.abs(start - end);
        var percentageVisible = (start / distance) * -1;
        var percentage = Math.abs(percentageVisible);
        document.body.style.setProperty( "--pixel-text", percentage )
      
    });
  }

  useEffect(() => {
    const PixelImg = document.getElementById('Pixel-Perfect-Slider');
    const PixelText = document.getElementById('Pixel-Perfect-Text-Wrap');
    console.log(window.innerWidth)

    if (window.innerWidth > 950) {
      // PixelImageAnimation(PixelImg)
      //   PixelTextAnimation(PixelText)
    }
  }, []);


  const PPStyle1 = {
    '--pptime1': '35s',
  };

  const PPStyle2 = {
    '--pptime2': '25s',
  };

  return (
    <div id="Pixel-Perfect-Section">
      <div id="Pixel-Perfect-Wrapper">
        <div id="Pixel-Perfect-Slider">
          <div className="PP-Slider-Inner-Wrap">
            <div className="PP-Slider-Inner" id="PP-Slider-1" style={PPStyle1}>
              <img className="PP-Slider-Img" src={MB1} />
              <img className="PP-Slider-Img" src={MB3} />
              <img className="PP-Slider-Img" src={MB5} />
            </div>
            <div className="PP-Slider-Inner" id="PP-Slider-1-2" style={PPStyle1}>
              <img className="PP-Slider-Img" src={MB1} />
              <img className="PP-Slider-Img" src={MB3} />
              <img className="PP-Slider-Img" src={MB5} />
            </div>
          </div>
          <div className="PP-Slider-Inner-Wrap">
            <div className="PP-Slider-Inner" id="PP-Slider-2" style={PPStyle2}>
              <img className="PP-Slider-Img" src={MB2} />
              <img className="PP-Slider-Img" src={MB4} />
              <img className="PP-Slider-Img" src={MB6} />
            </div>
            <div className="PP-Slider-Inner" id="PP-Slider-2-2" style={PPStyle2}>
              <img className="PP-Slider-Img" src={MB2} />
              <img className="PP-Slider-Img" src={MB4} />
              <img className="PP-Slider-Img" src={MB6} />
            </div>
        </div>
          </div>
        <div id="Pixel-Perfect-Text-Wrap">
          <h2 className="Headline Pixel-Headline">Pixel perfect across every device</h2>
          <p className="Text Pixel-Text">
            We’ll work directly with our marketing & design team to ensure your store looks and performs to a world-class level, no matter the device it’s viewed on.
          </p>
        </div>
      </div>
    </div>
  )
}

export default PixelPerfect