import './BlankCanvas.css'
import Icon1 from './Icon1.svg'
import Icon2 from './Icon2.svg'
import Icon3 from './Icon3.svg'
import SelectorIcon1 from './SelectorIcon1.svg'
import SelectorIcon2 from './SelectorIcon2.svg'
import SelectorIcon3 from './SelectorIcon3.svg'
import CanvasCircle from './CanvasCircle.svg'



const BlankCanvas = () => {

    const changeItem = (toSelectId, canvasVideo) => {
        console.log('fire')
        const WindowWidth = window.innerWidth

        var currentSelected = ''
        var CanvasVideo = ''

        if (WindowWidth > 920) {
            currentSelected = document.querySelector('.Active-Canvas-Selector')
            console.log(currentSelected)
            const toSelect = document.getElementById(toSelectId)
            CanvasVideo = document.getElementById(canvasVideo)
            console.log(CanvasVideo)
    
            currentSelected.classList.remove('Active-Canvas-Selector')
            toSelect.classList.add('Active-Canvas-Selector')
        } else {
            currentSelected = document.querySelector('.Active-Canvas-Selector-MB')
            console.log(currentSelected)
            const toSelect = document.getElementById(toSelectId)
            CanvasVideo = document.getElementById(canvasVideo)
            console.log(CanvasVideo)
    
            currentSelected.classList.remove('Active-Canvas-Selector-MB')
            toSelect.classList.add('Active-Canvas-Selector-MB')

        }


        if (currentSelected.id === 'Canvas-Selector-1') {
            document.getElementById('Canvas-Video-1').classList.add('Hidden')
        } else if (currentSelected.id === 'Canvas-Selector-2') {
                document.getElementById('Canvas-Video-2').classList.add('Hidden')

        } else if (currentSelected.id === 'Canvas-Selector-3') {
                document.getElementById('Canvas-Video-3').classList.add('Hidden')
        } else if (currentSelected.id === 'Canvas-Selector-MB-1') {
                document.getElementById('Canvas-Video-MB-1').classList.add('Hidden')
        } else if (currentSelected.id === 'Canvas-Selector-MB-2') {
                document.getElementById('Canvas-Video-MB-2').classList.add('Hidden')
        } else if (currentSelected.id === 'Canvas-Selector-MB-3') {
                document.getElementById('Canvas-Video-MB-3').classList.add('Hidden')
        }

        CanvasVideo.classList.remove('Hidden')


    }

    return (
        <div id="Blank-Canvas" style={{ backgroundImage: 'linear-gradient(rgb(18, 20, 29), rgb(23, 25, 36) 0%, rgb(18, 20, 29) 103%)'}}>
            <img id="Blank-Canvas-Circle" src={CanvasCircle} />
            <h2 className='Canvas-SubHead'>We’ll build you a unique store from zero that’s</h2>
            <h2 className="Headline Canvas-Headline">Designed & hand-coded to meet your exact needs</h2>
            <div id="Blank-Canvas-Container">

                <div id="Canvas-Selector-Wrap">
                    <div className="Canvas-Selector Active-Canvas-Selector Hover-Up" id='Canvas-Selector-1' onClick={() => changeItem('Canvas-Selector-1', 'Canvas-Video-1')}>
                        <img className="Canvas-Selector-Icon " src={SelectorIcon1} />
                        <h2 className="Canvas-Selector-Text">Product pages that sell</h2>
                    </div>
                    <div className="Canvas-Selector Hover-Up" id="Canvas-Selector-2"  onClick={() => changeItem('Canvas-Selector-2', 'Canvas-Video-2')}>
                        <img className="Canvas-Selector-Icon" src={SelectorIcon2} />
                        <h2 className="Canvas-Selector-Text">Clean collection pages</h2>
                    </div>
                    <div className="Canvas-Selector Hover-Up" id="Canvas-Selector-3" onClick={() => changeItem('Canvas-Selector-3', 'Canvas-Video-3')}>
                        <img className="Canvas-Selector-Icon" src={SelectorIcon3}  />
                        <h2 className="Canvas-Selector-Text">Cart pages that convert</h2>
                    </div>
                </div>

                <div id="Canvas-Selector-Wrap-Mobile">
                    <div className="Canvas-Selector Active-Canvas-Selector-MB" id='Canvas-Selector-MB-1' onClick={() => changeItem('Canvas-Selector-MB-1', 'Canvas-Video-MB-1')}>
                        <img className="Canvas-Selector-Icon " src={SelectorIcon1} />
                        <h2 className="Canvas-Selector-Text">Product pages that sell</h2>
                    </div>
                    <div className="Canvas-Video" id="Canvas-Video-MB-1">
                        <div class="Wistia-Player" data-video-id="qdj1mvareq" data-wrapper-id="wistia-player-container-111"  data-video-thumb-id="Vid-Thumb-111" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-111">
                            <div className="Wistia-Container" id="wistia-player-container-111"></div>
                            <div className="Vid-Loader-Container" id="Vid-Thumb-111" >
                                <div className="Vid-Container-Loader"></div>
                                <img className="Vid-Loader-Img" src="https://mavric.sirv.com/11.webp" />
                            </div>
                        </div>
                    </div>
                    <div className="Canvas-Selector" id="Canvas-Selector-MB-2"  onClick={() => changeItem('Canvas-Selector-MB-2', 'Canvas-Video-MB-2')}>
                        <img className="Canvas-Selector-Icon" src={SelectorIcon2} />
                        <h2 className="Canvas-Selector-Text">Clean collection pages</h2>
                    </div>
                    <div className="Canvas-Video Hidden" id="Canvas-Video-MB-2">
                        <div class="Wistia-Player" data-video-id="p1pcwlgqmj" data-wrapper-id="wistia-player-container-222"  data-video-thumb-id="Vid-Thumb-222" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-222">
                            <div className="Wistia-Container" id="wistia-player-container-222"></div>
                            <div className="Vid-Loader-Container" id="Vid-Thumb-222" >
                                <div className="Vid-Container-Loader"></div>
                                <img className="Vid-Loader-Img" src="https://mavric.sirv.com/33.webp" />
                            </div>
                        </div>
                    </div>
                    <div className="Canvas-Selector" id="Canvas-Selector-MB-3" onClick={() => changeItem('Canvas-Selector-MB-3', 'Canvas-Video-MB-3')}>
                        <img className="Canvas-Selector-Icon" src={SelectorIcon3}  />
                        <h2 className="Canvas-Selector-Text">Cart pages that convert</h2>
                    </div>
                    <div className="Canvas-Video Hidden" id="Canvas-Video-MB-3">
                        <div class="Wistia-Player" data-video-id="sensgndv9c" data-wrapper-id="wistia-player-container-333"  data-video-thumb-id="Vid-Thumb-333" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-333">
                            <div className="Wistia-Container" id="wistia-player-container-333"></div>
                            <div className="Vid-Loader-Container" id="Vid-Thumb-333" >
                                <div className="Vid-Container-Loader"></div>
                                <img className="Vid-Loader-Img" src="https://mavric.sirv.com/22.webp" />
                            </div>
                        </div>
                    </div>
                </div>
                    <div id="Canvas-Video-Wrappper">

                    <div className="Canvas-Video" id="Canvas-Video-1">
                        <div class="Wistia-Player" data-video-id="qdj1mvareq" data-wrapper-id="wistia-player-container-1"  data-video-thumb-id="Vid-Thumb-1" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-1">
                            <div className="Wistia-Container" id="wistia-player-container-1"></div>
                            <div className="Vid-Loader-Container" id="Vid-Thumb-1" >
                                <div className="Vid-Container-Loader"></div>
                                <img className="Vid-Loader-Img" src="https://mavric.sirv.com/11.webp" />
                            </div>
                        </div>
                    </div>
                    <div className="Canvas-Video Hidden" id="Canvas-Video-2">
                        <div class="Wistia-Player" data-video-id="p1pcwlgqmj" data-wrapper-id="wistia-player-container-2"  data-video-thumb-id="Vid-Thumb-2" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-2">
                            <div className="Wistia-Container" id="wistia-player-container-2"></div>
                            <div className="Vid-Loader-Container" id="Vid-Thumb-2" >
                                <div className="Vid-Container-Loader"></div>
                                <img className="Vid-Loader-Img" src="https://mavric.sirv.com/33.webp" />
                            </div>
                        </div>
                    </div>
                    <div className="Canvas-Video Hidden" id="Canvas-Video-3">
                        <div class="Wistia-Player" data-video-id="sensgndv9c" data-wrapper-id="wistia-player-container-3"  data-video-thumb-id="Vid-Thumb-3" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-3">
                            <div className="Wistia-Container" id="wistia-player-container-3"></div>
                            <div className="Vid-Loader-Container" id="Vid-Thumb-3" >
                                <div className="Vid-Container-Loader"></div>
                                <img className="Vid-Loader-Img" src="https://mavric.sirv.com/22.webp" />
                            </div>
                        </div>
                    </div>
                    </div>
            </div>

        </div>
    )
}

export default BlankCanvas