import './FAQs.css'
import MinusIcon from '../Images/MinsIcon.svg'
import PlusIcon from '../Images/PlusIcon.svg'

const FAQs = () => {

    const ToggleFAQs = (showId, showIconId) => {

        const showContent = document.getElementById(showId)
        const otherContent = document.querySelectorAll(`.FAQ-Text:not(#${showId})`)
        otherContent.forEach((item) => {
            item.classList.add('Hidden')
        })

        const showIcon = document.getElementById(showIconId)
        if (showContent.classList.contains('Hidden')) {
            showIcon.src = MinusIcon

            showContent.classList.remove('Hidden')
        } else {
            showContent.classList.add('Hidden')
            showIcon.src = PlusIcon

        }

        
        const hideIcon = document.querySelectorAll(`.FAQ-Icon:not(#${showIconId})`)
        hideIcon.forEach((item) => {
            if (item != showIcon) {
                item.src = PlusIcon
            }
        })



    }
    return (
        <div id="FAQs">
            <div id="FAQ-Container">
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-1', 'FAQ-Icon-1')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">Hows does it work?</h2>
                        <h2 id="FAQ-Content-1" className="FAQ-Text Hidden">
                            Upon completing your first instalment, you will be taken to your own client portal. Here, we've created a special process where you can provide us with all the necessary information about your store/company.
                            <br/>
                            <br/>
                            You'll have the opportunity to provide us with all the information you'd like, including the types of stores you'd like to model, specific functions you'd like to include, designs you're inspired by, plus a bunch of additional information that will help give us a perfectly clear idea of exactly the type of store you're after.
                            <br />
                            <br />
                            Once we have this information, we will immediately begin to craft an initial game-plan for your new store, whilst being in direct communication with you - updating you along the way, and working with you to ensure you end up with the store you've always wanted.
                            <br />
                            <br />
                            After we have wireframes for your entire store, and have all the functionalities you'd like mapped out - we will present the game-plan to you. Here, you can let us know if you're happy with it, or if there's any changes that need to be made.
                            <br />
                            <br />
                            Once you sign-off on the final game-plan, we will begin at once to design and code your store. Keeping you updated on the progress, and in the loop of how everything's going, as we understand this can be an exciting process you'd like to be a part of.
                            <br />
                            <br />
                            Along the way, you'll be able to let us know of any tweaks you'd like to be made, and once the store has been fully created, we will present it to you. At this point, you'll have another opportunity to review it and let us know if you're happy, or if there are any modifications to be made.
                            <br />
                            <br />
                            Finally, once you're happy, we will transfer the full ownership of the store to your account! Pretty simple, right? :)
                        </h2>
                    </div>
                    <img id="FAQ-Icon-1" className="FAQ-Icon" src={PlusIcon} />
                </div>
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-2', 'FAQ-Icon-2')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">How do the instalments work?</h2>
                        <h2 id="FAQ-Content-2" className="FAQ-Text Hidden">When you get started today, your first deposit is for the initial game-plan, design and build for your store (see the refund policy). From this instalment, it will cover the entire build for your store - from start to finish.
                        
                        <br/>
                        <br/>
                        Once we have finished your store and you confirm that you're happy with it, we now transition into the handover phase. Here, once the last instalment is covered, we will transfer the store to your Shopify account where you will gain full, 100% ownership of your new store.
                        </h2>
                    </div>
                    <img id="FAQ-Icon-2" className="FAQ-Icon" src={PlusIcon} />
                </div>
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-7', 'FAQ-Icon-7')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">Is there a refund period?</h2>
                        <h2 id="FAQ-Content-7" className="FAQ-Text Hidden">For sure! After your first instalment, you & I will work together to craft the perfect game-plan for your store. If at this point for whatever reason you're not happy with the plan, or the direction we are heading - simply let us know and we will refund your initial deposit.
                        <br />
                        <br />
                        Once you confirm you're happy with the plan, we will begin to create your new store. From this point forth, the initial deposit becomes non-refundable and we will continue to build your store as we agreed until it's complete.
                        </h2>
                    </div>
                    <img id="FAQ-Icon-7" className="FAQ-Icon" src={PlusIcon} />
                </div>
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-3', 'FAQ-Icon-3')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">What if I don’t currently have a store?</h2>
                        <h2 id="FAQ-Content-3" className="FAQ-Text Hidden">
                            No problems at all. We can either work with you from the ground-up to create a brand new store, or we can take the existing store you might currently have, and re-vamp it into the store you've always wanted. Either way is totally fine with us!
                        </h2>
                    </div>
                    <img id="FAQ-Icon-3" className="FAQ-Icon" src={PlusIcon} />
                </div>
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-4', 'FAQ-Icon-4')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">Why is spacing so limited?</h2>
                        <h2 id="FAQ-Content-4" className="FAQ-Text Hidden">
                            Due to the hands-on nature of this process, we can only take on a select number of clients each month. This is to ensure that you have the best experience possible, your store is of the highest quality, and is delivered to you in the fastest, most optimised timeframe.
                        </h2>
                    </div>
                    <img id="FAQ-Icon-4" className="FAQ-Icon" src={PlusIcon} />
                </div>
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-5', 'FAQ-Icon-5')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">Will I get ongoing support?</h2>
                        <h2 id="FAQ-Content-5" className="FAQ-Text Hidden">
                            Yeah baby! Depending on the package you select, you will get 3-6 months of ongoing support. This will cover any upgrades of your store, in the rare case that you find anything that's not working as promised. Any additional customisations, or changes to your store can also be arranged at a significantly discounted rate.
                        </h2>
                    </div>
                    <img id="FAQ-Icon-5" className="FAQ-Icon" src={PlusIcon} />
                </div>
                <div className="FAQ-Item No-Outline" onClick={() => ToggleFAQs('FAQ-Content-6', 'FAQ-Icon-6')}>
                    <div className="FAQ-Content">
                        <h2 className="FAQ-Title">What if I have additional questions?</h2>
                        <h2 id="FAQ-Content-6" className="FAQ-Text Hidden">
                            Not a problem! You can click on the "Chat with us" icon in the bottom right side of your screen. Here, you can find options to either live chat with us, chat via whatsapp, email, or even book a short consultation call with us. Whatever means you select - we are here to answer any and every question you might have, as we understand this process can potentially be a little confusing, and you need to be sure that you're working with someone who can deliver you exactly what you're looking for... 
                        </h2>
                    </div>
                    <img id="FAQ-Icon-6" className="FAQ-Icon" src={PlusIcon} />
                </div>
            </div>
        </div>
    )
}

export default FAQs