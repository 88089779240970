import { useEffect } from 'react';
import SEO from './10 - SEO/SEO';
import Apps from './11 - Apps/Apps';
import Bonus from './12 - Bonus/Bonus';
import CTA from './13 - CTA/CTA';
import FAQs from './14 - FAQs/FAQs';
import Footer from './15 - Footer/Footer';
import TopSection from './2 - Top Section/TopSection'
import GenericThemes from './3 - Generic Themes/GenericThemes';
import BlankCanvas from './4 - Blank Canvas/BlankCanvas';
import NoLimits from './5 - No Limits/NoLimits';
import Proud from './6 - Proud/Proud';
import PixelPerfect from './7.5 - Pixel Perfect/PixelPerfect';
import Speed from './8 - Speed/Speed';
import SalesCopy from './9 - Sales Copy/SalesCopy';
import Terms from './16 - Terms Pop Up/Terms';
import LiveChat from './0 - Live Chat/LiveChat';
import Privacy from './17 - Privacy Pop Up/Privacy';
import Refund from './18 - Refund Policy/Refund';
import { React } from 'react'
import SpeedTest from './6.5 - Speed Test/SpeedTest';
import IntroSection from './3 - Intro Section/IntroSection';

function App() {

  const LazyLoad = () => {
    const lazyloadImages = document.querySelectorAll(".Image-Container");

    const loadImage = (imageContainer) => {
        const imgLarge = new Image();

        // if (window.innerWidth < 800 && imageContainer.dataset.med) {
        //     imgLarge.src = imageContainer.dataset.med;
        // } else if (window.innerWidth < 500 && imageContainer.dataset.small) {
        //     imgLarge.src = imageContainer.dataset.small;
        // } else {
            imgLarge.src = imageContainer.dataset.large;
        // }

        imgLarge.onload = () => {
            imgLarge.classList.add('Loaded');
            imageContainer.childNodes[0].classList.add('Gone');
        };
        imgLarge.classList.add('Picture');
        imageContainer.appendChild(imgLarge);

        if (imageContainer.id === 'Pixel-Img') {
            imageContainer.parentNode.classList.add('Pixel-Image');
        }
    };

    if ("IntersectionObserver" in window) {
        const imageObserver = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    loadImage(entry.target);
                    observer.unobserve(entry.target);
                }
            });
        }, { rootMargin: "0px 0px 100% 0px" });

        lazyloadImages.forEach((imageContainer) => {
            imageObserver.observe(imageContainer);
        });

        

    } else { // Fallback for browsers that do not support IntersectionObserver
        lazyloadImages.forEach((imageContainer) => {
            loadImage(imageContainer);
        });
    }
};

  
const LazyLoadVids = () => {

    const WindowHeight = window.innerHeight

    console.log('LL')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
      
        if (entry.isIntersecting) {
            console.log('is')
          const target = entry.target;
          const videoId = target.getAttribute("data-video-id");
          const wrapperId = target.getAttribute("data-wrapper-id");
          const wrapper = document.getElementById(wrapperId)
          const thumbId = target.getAttribute("data-video-thumb-id");
          const loadScriptOnce = target.getAttribute("data-load-script-once")
          const hideThumb = target.getAttribute("data-hide-thumb")
          const hideThumbId = target.getAttribute("data-hide-thumb-id")
    
        //   if (loadScriptOnce) {
            const script2 = document.createElement("script");
            script2.src = "https://fast.wistia.com/assets/external/E-v1.js";
            script2.async = true;
            wrapper.appendChild(script2);  
            console.log(wrapper)
        //   }
     
          // Load the scripts only if loadScriptsOnce is false or the scripts have not been loaded before
          const script1 = document.createElement("script");
          script1.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;
          script1.async = true;
          wrapper.appendChild(script1);
    
          const div = document.createElement("div");
          div.className = `wistia_embed wistia_async_${videoId} seo=false videoFoam=true`;
          div.innerHTML = `<div class="wistia_swatch" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;"></div>`;
          wrapper.appendChild(div);
          console.log(wrapper)
    
          window._wq = window._wq || [];
          window._wq.push({ id: videoId, onReady: function(video) {
            console.log("Video is ready to play:", video.name());
            if (hideThumb) {
                console.log('HIde thumb')
              document.getElementById(hideThumbId).style.opacity = '0'
            //   document.getElementById('Hide-Img').style.opacity = '0'
            }
            video.bind('play', function() {
              console.log('Wistia video started playing');
              document.getElementById(thumbId).classList.add('Opacity')
            });
          }});
    
          observer.unobserve(target);
        }
      });
    // })
    }, { rootMargin: "0px 0px 100% 0px" }); // Adjusted rootMargin value;
    
    const wistiaPlayers = document.getElementsByClassName("Wistia-Player");
    Array.from(wistiaPlayers).forEach((player) => {
        console.log(player)
      observer.observe(player);
    });
    


  }





  useEffect(() => {
    LazyLoad()
    LazyLoadVids()
  }, [])


  return (

    <>
    <TopSection />
    {/* <GenericThemes /> */}
    <IntroSection />
    <BlankCanvas />
    <NoLimits />
    <Proud />
    {/* <PixelPerfect /> */}
    {/* <Speed /> */}
    {/* <SalesCopy /> */}
    {/* <SEO /> */}
    {/* <Apps /> */}
    <SpeedTest />
    <PixelPerfect />
    <Bonus />
    <CTA />
    <FAQs />
    <Footer />
    <Terms />
    <Privacy />
    <Refund />
    <LiveChat />
    </>

  );
}

export default App;
