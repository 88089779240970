import React from 'react'
import '../16 - Terms Pop Up/Terms.css'
import CloseIcon from '../16 - Terms Pop Up/CloseIcon.svg'

const Privacy = () => {

    const HideTerms = () => {
        document.getElementById('Privacy-Popup').classList.add('Hidden')
    }
    
  return (
    <div id="Privacy-Popup" className="Popup-Container Hidden">

        <div className="Popup-Content">
            <div className="Popup-Header">
                <h2 className="Popup-Title">Privacy Policy</h2>
                <img id="Close-Icon" src={CloseIcon}  onClick={() => HideTerms()} />
            </div>
            <div className="Popup-Inner">
            <p className="Terms-Text">
        Last updated August 15, 2023
        This privacy notice for Mavric ('Company', 'we', 'us', or 'our'), describes how and why we might collect, store,
        use, and/or share ('process') your information when you use our services ('Services'), such as when you:
        Visit our website at https://mavric.ai, or any website of ours that links to this privacy notice
        Engage with us in other related ways, including any sales, marketing, or events
        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If
        you do not agree with our policies and practices, please do not use our Services. If you still have any questions or
        concerns, please contact us at tom@mavric.ai.
        SUMMARY OF KEY POINTS
        This summary provides key points from our privacy notice, but you can find out more details about any
        of these topics by clicking the link following each key point or by using our table of contents below to
        find the section you are looking for.
        What personal information do we process? When you visit, use, or navigate our Services, we may process
        personal information depending on how you interact with Mavric and the Services, the choices you make, and the
        products and features you use. Learn more about personal information you disclose to us.
        Do we process any sensitive personal information? We do not process sensitive personal information.
        Do we receive any information from third parties? We do not receive any information from third parties.
        How do we process your information? We process your information to provide, improve, and administer our
        Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
        your information for other purposes with your consent. We process your information only when we have a valid
        legal reason to do so. Learn more about how we process your information.
        In what situations and with which parties do we share personal information? We may share information in
        specific situations and with specific third parties. Learn more about when and with whom we share your personal
        information.
        What are your rights? Depending on where you are located geographically, the applicable privacy law may
        mean you have certain rights regarding your personal information. Learn more about your privacy rights.
        How do you exercise your rights? The easiest way to exercise your rights is by submitting a data subject
        access request, or by contacting us. We will consider and act upon any request in accordance with applicable
        data protection laws.
        Want to learn more about what Mavric does with any information we collect? Review the privacy notice in full.
        15/08/2023, 18:40 Termly
        https://app.termly.io/dashboard/website/b191344e-1f70-4803-bff1-8b28edb2f502/privacy-policy 2/7
        TABLE OF CONTENTS
        1. WHAT INFORMATION DO WE COLLECT?
        2. HOW DO WE PROCESS YOUR INFORMATION?
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
        7. DO WE COLLECT INFORMATION FROM MINORS?
        8. WHAT ARE YOUR PRIVACY RIGHTS?
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        11. DO WE MAKE UPDATES TO THIS NOTICE?
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        1. WHAT INFORMATION DO WE COLLECT?
        Personal information you disclose to us
        In Short: We collect personal information that you provide to us.
        We collect personal information that you voluntarily provide to us when you express an interest in obtaining
        information about us or our products and Services, when you participate in activities on the Services, or otherwise
        when you contact us.
        Personal Information Provided by You. The personal information that we collect depends on the context of
        your interactions with us and the Services, the choices you make, and the products and features you use. The
        personal information we collect may include the following:
        names
        email addresses
        Sensitive Information. We do not process sensitive information.
        Payment Data. We may collect data necessary to process your payment if you make purchases, such as your
        payment instrument number, and the security code associated with your payment instrument. All payment data is
        stored by __________. You may find their privacy notice link(s) here: __________.
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of
        any changes to such personal information.
        Information automatically collected
        In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device
        characteristics — is collected automatically when you visit our Services.
        15/08/2023, 18:40 Termly
        https://app.termly.io/dashboard/website/b191344e-1f70-4803-bff1-8b28edb2f502/privacy-policy 3/7
        We automatically collect certain information when you visit, use, or navigate the Services. This information does
        not reveal your specific identity (like your name or contact information) but may include device and usage
        information, such as your IP address, browser and device characteristics, operating system, language
        preferences, referring URLs, device name, country, location, information about how and when you use our
        Services, and other technical information. This information is primarily needed to maintain the security and
        operation of our Services, and for our internal analytics and reporting purposes.
        Like many businesses, we also collect information through cookies and similar technologies.
        2. HOW DO WE PROCESS YOUR INFORMATION?
        In Short: We process your information to provide, improve, and administer our Services, communicate with you,
        for security and fraud prevention, and to comply with law. We may also process your information for other
        purposes with your consent.
        We process your personal information for a variety of reasons, depending on how you interact with our
        Services, including:
        To save or protect an individual's vital interest. We may process your information when necessary
        to save or protect an individual’s vital interest, such as to prevent harm.
        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR
        INFORMATION?
        In Short: We only process your personal information when we believe it is necessary and we have a valid legal
        reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you
        with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate
        business interests.
        If you are located in the EU or UK, this section applies to you.
        The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we
        rely on in order to process your personal information. As such, we may rely on the following legal bases to
        process your personal information:
        Consent. We may process your information if you have given us permission (i.e. consent) to use your
        personal information for a specific purpose. You can withdraw your consent at any time. Learn more
        about withdrawing your consent.
        Legal Obligations. We may process your information where we believe it is necessary for
        compliance with our legal obligations, such as to cooperate with a law enforcement body or
        regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in
        litigation in which we are involved.
        Vital Interests. We may process your information where we believe it is necessary to protect your
        vital interests or the vital interests of a third party, such as situations involving potential threats to the
        safety of any person.
        15/08/2023, 18:40 Termly
        https://app.termly.io/dashboard/website/b191344e-1f70-4803-bff1-8b28edb2f502/privacy-policy 4/7
        If you are located in Canada, this section applies to you.
        We may process your information if you have given us specific permission (i.e. express consent) to use
        your personal information for a specific purpose, or in situations where your permission can be inferred
        (i.e. implied consent). You can withdraw your consent at any time.
        In some exceptional cases, we may be legally permitted under applicable law to process your information
        without your consent, including, for example:
        If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
        For investigations and fraud detection and prevention
        For business transactions provided certain conditions are met
        If it is contained in a witness statement and the collection is necessary to assess, process, or settle
        an insurance claim
        For identifying injured, ill, or deceased persons and communicating with next of kin
        If we have reasonable grounds to believe an individual has been, is, or may be victim of financial
        abuse
        If it is reasonable to expect collection and use with consent would compromise the availability or the
        accuracy of the information and the collection is reasonable for purposes related to investigating a
        breach of an agreement or a contravention of the laws of Canada or a province
        If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court
        relating to the production of records
        If it was produced by an individual in the course of their employment, business, or profession and the
        collection is consistent with the purposes for which the information was produced
        If the collection is solely for journalistic, artistic, or literary purposes
        If the information is publicly available and is specified by the regulations
        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
        INFORMATION?
        In Short: We may share information in specific situations described in this section and/or with the following third
        parties.
        We may need to share your personal information in the following situations:
        Business Transfers. We may share or transfer your information in connection with, or during negotiations
        of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to
        another company.
        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        15/08/2023, 18:40 Termly
        https://app.termly.io/dashboard/website/b191344e-1f70-4803-bff1-8b28edb2f502/privacy-policy 5/7
        In Short: We may use cookies and other tracking technologies to collect and store your information.
        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
        information. Specific information about how we use such technologies and how you can refuse certain cookies is
        set out in our Cookie Notice.
        6. HOW LONG DO WE KEEP YOUR INFORMATION?
        In Short: We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice
        unless otherwise required by law.
        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy
        notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal
        requirements).
        When we have no ongoing legitimate business need to process your personal information, we will either delete
        or anonymise such information, or, if this is not possible (for example, because your personal information has
        been stored in backup archives), then we will securely store your personal information and isolate it from any
        further processing until deletion is possible.
        7. DO WE COLLECT INFORMATION FROM MINORS?
        In Short: We do not knowingly collect data from or market to children under 18 years of age.
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you
        represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such
        minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age
        has been collected, we will deactivate the account and take reasonable measures to promptly delete such data
        from our records. If you become aware of any data we may have collected from children under age 18, please
        contact us at __________.
        8. WHAT ARE YOUR PRIVACY RIGHTS?
        In Short: In some regions, such as the European Economic Area (EEA), United Kingdom (UK), and Canada, you
        have rights that allow you greater access to and control over your personal information. You may review, change,
        or terminate your account at any time.
        In some regions (like the EEA, UK, and Canada), you have certain rights under applicable data protection laws.
        These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request
        rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data
        portability. In certain circumstances, you may also have the right to object to the processing of your personal
        information. You can make such a request by contacting us by using the contact details provided in the
        section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
        We will consider and act upon any request in accordance with applicable data protection laws.
        15/08/2023, 18:40 Termly
        https://app.termly.io/dashboard/website/b191344e-1f70-4803-bff1-8b28edb2f502/privacy-policy 6/7
        If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you
        also have the right to complain to your Member State data protection authority or UK data protection authority.
        If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.
        Withdrawing your consent: If we are relying on your consent to process your personal information, which may
        be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent
        at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in
        the section 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?' below.
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when
        applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful
        processing grounds other than consent.
        Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you
        can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies
        or reject cookies, this could affect certain features or services of our Services. You may also opt out of interestbased advertising by advertisers on our Services.
        If you have questions or comments about your privacy rights, you may email us at tom@mavric.ai.
        9. CONTROLS FOR DO-NOT-TRACK FEATURES
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT')
        feature or setting you can activate to signal your privacy preference not to have data about your online browsing
        activities monitored and collected. At this stage no uniform technology standard for recognising and implementing
        DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other
        mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking
        is adopted that we must follow in the future, we will inform you about that practice in a revised version of this
        privacy notice.
        10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal
        information.
        California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are
        California residents to request and obtain from us, once a year and free of charge, information about categories
        of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
        addresses of all third parties with which we shared personal information in the immediately preceding calendar
        year. If you are a California resident and would like to make such a request, please submit your request in writing
        to us using the contact information provided below.
        If you are under 18 years of age, reside in California, and have a registered account with Services, you have the
        right to request removal of unwanted data that you publicly post on the Services. To request removal of such
        data, please contact us using the contact information provided below and include the email address associated
        with your account and a statement that you reside in California. We will make sure the data is not publicly
        15/08/2023, 18:40 Termly
        https://app.termly.io/dashboard/website/b191344e-1f70-4803-bff1-8b28edb2f502/privacy-policy 7/7
        displayed on the Services, but please be aware that the data may not be completely or comprehensively removed
        from all our systems (e.g. backups, etc.).
        11. DO WE MAKE UPDATES TO THIS NOTICE?
        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
        We may update this privacy notice from time to time. The updated version will be indicated by an
        updated 'Revised' date and the updated version will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
        directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of
        how we are protecting your information.
        12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        If you have questions or comments about this notice, you may email us at tom@mavric.ai or contact us by post at:
        Mavric
        Barnstone, New Road
        Cheltenham, GL52 9PX
        United Kingdom
        13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
        FROM YOU?
        You have the right to request access to the personal information we collect from you, change that information, or
        delete it. To request to review, update, or delete your personal information, please fill out and submit a data
        </p>
            </div>
            <div className="Popup-Bottom" onClick={() => HideTerms()}>
                <div className="Disagree-Btn">
                    <h2 className="Disagree-Text">Disagree & Exit</h2>
                </div>
                <div className="Agree-Btn" onClick={() => HideTerms()}>
                    <h2 className="Agree-Text">Agree & Continue On Site</h2>
                </div>

            </div>
        </div>
        <div className="Popup-Background" onClick={() => HideTerms()}></div>
      
    </div>
  )
}

export default Privacy;
