
import { React } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from "./Home";
import Confirmed from './Confirmed/Confirmed'

function App() {

  return (
    <>
    <BrowserRouter>
      <Routes>    
      <Route path="/" element={<Home/>} />
      <Route path="/confirmed" element={<Confirmed/>} />
      
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
