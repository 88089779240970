import React from 'react'
import { Crisp } from "crisp-sdk-web";
import './LiveChat.css'
import CloseIcon from './CloseIcon.svg'
import ArrowRight from './Arrow-Right.svg'
import Icon1 from './Icon1.svg'
import Icon2 from './Icon2.svg'
import Icon3 from './Icon3.svg'
import Icon4 from './Icon4.svg'

Crisp.configure('f6e020f6-76a5-49fc-ae35-a5bd9c14eb63', {
    autoload: false
  });

 const LiveChat = () => {

    const HideLiveChat = () => {
        document.getElementById('Live-Chat-Container').classList.remove('Show-Chat');
        document.getElementById('Live-Chat-BG').classList.add('Hidden')
    };

    const StartLiveChat = () => {
        Crisp.chat.show();
        Crisp.chat.open();


        document.getElementById('Start-Chat').innerText = "Starting live chat..."

        const interval = setInterval(() => {
            const isOpened = Crisp.chat.isVisible();
            if (isOpened) {
                console.log("Chat is open");
                document.getElementById('Live-Chat-Container').classList.remove('Show-Chat');
                document.getElementById('Start-Chat').innerText = "Start live chat"
                clearInterval(interval); // Stop the interval when chat is open

            } else {
                console.log("Chat is closed");
            }
          }, 100); // Change the interval time as per your requirement
      
          return () => {
            clearInterval(interval)
          }

    }

    
  return (
    <>

    <div id="Live-Chat-BG" className="Hidden"></div>
        <div id="Live-Chat-Container" className="Opacity">
            <div id="Live-Chat-Top">
                <h2 id="Live-Chat-Top-Text">Oh, hey there 👋 <br />How can we help you?</h2>
                <img id="Live-Chat-Close" className="No-Outline" src={CloseIcon} onClick={() => HideLiveChat()}/>
            </div>
            <div id="Live-Chat-Middle">
                <a id="Show-Tidio" className="Live-Chat-Btn No-Outline" onClick={() =>{ StartLiveChat()}}>
                    <div className="Live-Chat-Inner">
                        <img className="Live-Chat-Icon" src={Icon1} />
                        <h2 className="Live-Chat-Btn-Text" id="Start-Chat">Start live chat</h2>
                    </div>
                    <img id="Live-Chat-Arrow" src={ArrowRight} />
                </a>
                <a href="https://api.whatsapp.com/send/?phone=6281237272304" target="_blank" className="Live-Chat-Btn No-Outline">
                    <div className="Live-Chat-Inner">
                        <img className="Live-Chat-Icon" src={Icon2} />
                        <h2 className="Live-Chat-Btn-Text">Start Whatsapp chat</h2>
                    </div>
                    <img id="Live-Chat-Arrow" src={ArrowRight} />
                </a>
                <a href="mailto:tom@mavric.ai" className="Live-Chat-Btn No-Outline">
                    <div className="Live-Chat-Inner">
                        <img className="Live-Chat-Icon" src={Icon3} />
                        <h2 className="Live-Chat-Btn-Text">Send an email</h2>
                    </div>
                    <img id="Live-Chat-Arrow" src={ArrowRight} />
                </a>
            </div>
            <div id="Live-Chat-Bottom">
                <a href="https://calendly.com/mavric/getstarted" target="_blank" className="Live-Chat-Btn Black-Btn No-Outline">
                    <div className="Live-Chat-Inner">
                        <img className="Live-Chat-Icon" src={Icon4} />
                        <h2 className="Live-Chat-Btn-Text White-Text">Book a call</h2>
                    </div>
                    <img id="Live-Chat-Arrow" src={ArrowRight} />
                </a>
            </div>
        
        </div>
    </>
  )
}

export default LiveChat
