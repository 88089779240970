import './NoLimits.css'
import Icon1 from './Icon1.svg'
import Icon2 from './Icon2.svg'
import Icon3 from './Icon3.svg'
import Icon4 from './Icon4.svg'
import Icon5 from './Icon5.svg'
import Icon6 from './Icon6.svg'
import Icon7 from './Icon7.svg'
import Icon8 from './Icon8.svg'
import NothingText from './Nothing-Text.png'

const NoLimits = () => {

    const ShowExtraBenefits = () => {
       
        document.getElementById('No-Limits-Item-Hide-1').classList.remove('Hide-Mobile-No-Limits-Item')
        document.getElementById('No-Limits-Item-Hide-2').classList.remove('Hide-Mobile-No-Limits-Item')
        document.getElementById('No-Limits-Item-Hide-3').classList.remove('Hide-Mobile-No-Limits-Item')

        document.getElementById('No-Limits-Hide-More').classList.remove('Hidden')
        document.getElementById('No-Limits-Load-More').classList.add('Hidden')
    }

    const HideExtraBenefits = () => {
    
        document.getElementById('No-Limits-Item-Hide-1').classList.add('Hide-Mobile-No-Limits-Item')
        document.getElementById('No-Limits-Item-Hide-2').classList.add('Hide-Mobile-No-Limits-Item')
        document.getElementById('No-Limits-Item-Hide-3').classList.add('Hide-Mobile-No-Limits-Item')

        document.getElementById('No-Limits-Hide-More').classList.add('Hidden')
        document.getElementById('No-Limits-Load-More').classList.remove('Hidden')
    }


    return (
        <div id="No-Limits">
            <div id="No-Limits-Container">
                <h2 className="Headline No-Limits-Headline">Featuring any designs or features you’d like...</h2>
                {/* <h2 className="Headline No-Limits-Headline-2">No design or <br/>functionality is off limits</h2> */}
                <h2 id="No-Limits-Subheadline">...whatever you can imagine, we can create!</h2>
                <div className="No-Limits-Item-Wrap">
                    <div className="No-Limits-Item">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon1} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">Offers & bundles</h2>
                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                    <div className="No-Limits-Item">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon5} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">Reward motivators</h2>
                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="No-Limits-Item-Wrap">
                    <div className="No-Limits-Item">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon2} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">One click upsells</h2>
                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                    <div className="No-Limits-Item">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon6} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">Collection filters</h2>

                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="No-Limits-Item-Wrap">
                    <div className="No-Limits-Item">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon3} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">Predictive search</h2>
                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                    <div className="No-Limits-Item Hide-Mobile-No-Limits-Item" id="No-Limits-Item-Hide-1">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon7} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">Customer rewards</h2>
                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="No-Limits-Item-Wrap Hide-Mobile-No-Limits-Item" id="No-Limits-Item-Hide-2">
                    <div className="No-Limits-Item">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon4} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">Post purchase offers</h2>
                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                    <div className="No-Limits-Item Hide-Mobile-No-Limits-Item" id="No-Limits-Item-Hide-3">
                        <div className="No-Limits-Item-Inner">
                            <img className="No-Limits-Icon" src={Icon8} />
                            <div className="No-Limits-Inner-Text">
                                <h2 className="No-Limits-Inner-Head">A/B split tests</h2>

                                <p className="No-Limits-Inner-Para">We're marketers first, so we will help you to design your store to maximize all your key metrics!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 id="No-Limits-Bonus-Text">...plus anything else!</h4>
                <div id="No-Limits-Bonus-Img-Wrap">
                    <img id="No-Limits-Bonus-Img" src={NothingText} />
                </div>
                <h4 id="No-Limits-Load-More" onClick={() => ShowExtraBenefits()}>+ Load more</h4>
                <h4 id="No-Limits-Hide-More" className="Hidden" onClick={() => HideExtraBenefits()}>- Show less</h4>
            </div>
        </div>
    )
}

export default NoLimits