import React, { useEffect } from 'react'
import MavLogo from '../Mav-Logo.svg'
import './Confirmed.css'
import '../1 - Top Menu/TopMenu.css'
import Footer from '../15 - Footer/Footer'
import Video from '../Images/Video.png'
import LiveChat from '../0 - Live Chat/LiveChat'
import Whatsapp from '../2 - Top Section/Whatsapp.svg'
import { ShowLiveChat } from '../2 - Top Section/TopSection'
import VidThumb from '../2 - Top Section/Video-Thumb.webp'
import { LazyLoadVids } from '../2 - Top Section/TopSection'

const Confirmed = () => {

    // useEffect(() => {
    //     LazyLoadVids()
    // },[])


    return (
    <>
    <div id="Confirmed-Header">
        <img src={MavLogo} />
    </div>
    <div id="Confirmed-Content">
        <h2 id="Confirmed-Title">Yeeha! You’re all signed up 🎉</h2>
        <h2 id="Confirmed-Sub">Please follow the steps in this short video, so we can hit the ground running...</h2>
        {/* <img src={Video} alt="Video" id="Confirmed-Video" /> */}
        <div class="Wistia-Player" id="Confirmed-Video" data-video-id="sapem137ws" data-wrapper-id="wistia-player-container"  data-video-thumb-id="Vid-Thumb" data-load-script-once="true" hide-thumb="true">
                    <div className='Wistia-Container' id="wistia-player-container"></div>
                    <div className="Vid-Loader-Container" id="Vid-Thumb" >
                        <div className="Vid-Container-Loader" id="Hide-Loader"></div>
                        <img className="Vid-Loader-Img" id="Hide-Img"src={VidThumb} />
                    </div>
                </div>
        <a href="https://docs.google.com/document/d/1W9en2h0IPn6w0li4592F6TleqJ3GFiwXYAQEiRkootg/template/preview" target="_blank" id="Confirmed-Btn">Access Your Quick Start Guide</a>
    </div>
    <div id="Chat-Container" className="No-Outline" onClick={() => ShowLiveChat()}>
        <img id="Chat-Icon" src={Whatsapp} />
        <h2 id="Chat-Text">Let's chat</h2>
    </div>
    <LiveChat />
    <Footer />
    </>
    )
    }

export default Confirmed


