import './Bonus.css'
import BonusShowcase from './Bonus-Showcase.png'
import BonusShowcaseSmall from './Bonus-Showcase-Small.png'
import SelectorIcon1 from '../4 - Blank Canvas/SelectorIcon1.svg'
import SelectorIcon2 from '../4 - Blank Canvas/SelectorIcon2.svg'
import SelectorIcon3 from '../4 - Blank Canvas/SelectorIcon3.svg'
import BonusCircle from './BonusCircle.svg'
import { useEffect } from 'react'


const Bonus = () => {

    function checkElementViewport(element) {
        document.addEventListener('scroll', function() {
          if (element.getBoundingClientRect().bottom < 0) {
            element.classList.remove('move-element');
          } else if (element.getBoundingClientRect().top > window.innerHeight) {
          } else {
            var start = element.getBoundingClientRect().top - window.innerHeight;
            var end = element.getBoundingClientRect().bottom;
            var distance = Math.abs(start - end);
            var percentageVisible = (start / distance) * -1;
            var percentage = Math.abs(percentageVisible);
            document.body.style.setProperty( "--showcase-1", percentage )
          }
        });
      }

      useEffect(() => {
        const element = document.getElementById('Showcase-1');
        if (window.innerWidth > 950) {
            checkElementViewport(element)
        }
      }, []);


      const changeItem = (toSelectId, canvasVideo) => {
        console.log('fire')
        const WindowWidth = window.innerWidth

        var currentSelected = ''
        var CanvasVideo = ''

        if (WindowWidth > 920) {
            currentSelected = document.querySelector('.Active-Bonus-Selector')
            console.log(currentSelected)
            const toSelect = document.getElementById(toSelectId)
            CanvasVideo = document.getElementById(canvasVideo)
            console.log(CanvasVideo)
    
            currentSelected.classList.remove('Active-Bonus-Selector')
            toSelect.classList.add('Active-Bonus-Selector')
        } else {
            currentSelected = document.querySelector('.Active-Bonus-Selector-MB')
            console.log(currentSelected)
            const toSelect = document.getElementById(toSelectId)
            CanvasVideo = document.getElementById(canvasVideo)
            console.log(CanvasVideo)
    
            currentSelected.classList.remove('Active-Bonus-Selector-MB')
            toSelect.classList.add('Active-Bonus-Selector-MB')

        }


        if (currentSelected.id === 'Bonus-Selector-1') {
            document.getElementById('Bonus-Img-1').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-2') {
                document.getElementById('Bonus-Img-2').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-3') {
                document.getElementById('Bonus-Img-3').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-4') {
                document.getElementById('Bonus-Img-4').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-5') {
                document.getElementById('Bonus-Img-5').classList.add('Hidden')
        } 

        else if (currentSelected.id === 'Bonus-Selector-MB-1') {
            document.getElementById('Bonus-Img-MB-1').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-MB-2') {
                document.getElementById('Bonus-Img-MB-2').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-MB-3') {
                document.getElementById('Bonus-Img-MB-3').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-MB-4') {
                document.getElementById('Bonus-Img-MB-4').classList.add('Hidden')
        } else if (currentSelected.id === 'Bonus-Selector-MB-5') {
                document.getElementById('Bonus-Img-MB-5').classList.add('Hidden')
        } 

        CanvasVideo.classList.remove('Hidden')


    }

      const ShowCaseTime = {
        '--showcaseTime': '35s',
      };


//   function BonusAnimation (element) {
//     document.addEventListener('scroll', function() {

//         var start = element.getBoundingClientRect().top - window.innerHeight;
//         var end = element.getBoundingClientRect().top - ((window.innerHeight / 100) * 40)
//         console.log(start, end)
//         var distance = Math.abs(start - end);
//         var percentageVisible = (start / distance) * -1;
//         var percentage = Math.abs(percentageVisible);
//         document.body.style.setProperty( "--bonus-section", percentage )
      
//     });
//   }

//   useEffect(() => {
//     const BonusSection = document.getElementById('Bonus');
//     console.log(window.innerWidth)

//     if (window.innerWidth > 950) {
//         BonusAnimation(BonusSection)
//     }
//   }, []);



    return (
        <div id="Bonus" style={{ backgroundImage: 'linear-gradient(#12141d, #171924 0%, #12141d 103%)' }}>
            <img id="Bonus-Circle" src={BonusCircle} />
            <div id="Bonus-Container">
                <h2 id="Bonus-Subhead">Plus free life-time access</h2>
                <h2 className="Headline Bonus-Headline">Get our Social Influencer system free for life 🎉</h2>
                <div id="Bonus-Inner-Container">
                    <div className="Bonus-Selector-Wrap">
                        <div className="Bonus-Selector No-Outline Active-Bonus-Selector Hover-Up" id="Bonus-Selector-1" onClick={() => changeItem('Bonus-Selector-1', 'Bonus-Img-1')} >
                            <img className="Bonus-Selector-Icon " src={SelectorIcon1} />
                            <h2 className="Bonus-Selector-Text">Manage customer content</h2>
                        </div>
                        <div className="Bonus-Selector No-Outline Hover-Up" id="Bonus-Selector-2" onClick={() => changeItem('Bonus-Selector-2', 'Bonus-Img-2')} >
                            <img className="Bonus-Selector-Icon" src={SelectorIcon2} />
                            <h2 className="Bonus-Selector-Text">Beautiful customer reviews</h2>
                        </div>
                        <div className="Bonus-Selector No-Outline Hover-Up" id="Bonus-Selector-3" onClick={() => changeItem('Bonus-Selector-3', 'Bonus-Img-3')} >
                            <img className="Bonus-Selector-Icon" src={SelectorIcon3} />
                            <h2 className="Bonus-Selector-Text">Boost social proof</h2>
                        </div>
                    </div>
                    <div className="Bonus-Selector-Wrap">
                        <div className="Bonus-Selector No-Outline Hover-Up" id="Bonus-Selector-4" onClick={() => changeItem('Bonus-Selector-4', 'Bonus-Img-4')} >
                            <img className="Bonus-Selector-Icon " src={SelectorIcon1} />
                            <h2 className="Bonus-Selector-Text">Automated follow ups</h2>
                        </div>
                        <div className="Bonus-Selector No-Outline Hover-Up" id="Bonus-Selector-5" onClick={() => changeItem('Bonus-Selector-5', 'Bonus-Img-5')} >
                            <img className="Bonus-Selector-Icon" src={SelectorIcon2} />
                            <h2 className="Bonus-Selector-Text">Incentivise content creation</h2>
                        </div>
                        <div className="Bonus-Selector No-Outline No-Pointer " id="Bonus-Selector-6">
                            <img className="Bonus-Selector-Icon" src={SelectorIcon3} />
                            <h2 className="Bonus-Selector-Text">Free lifetime access</h2>
                        </div>
                    </div>
                    <div className="Bonus-Selector-Wrap-Mobile">
                        <div className="Bonus-Selector Active-Bonus-Selector-MB" id="Bonus-Selector-MB-1" onClick={() => changeItem('Bonus-Selector-MB-1', 'Bonus-Img-MB-1')} >
                            <img className="Bonus-Selector-Icon " src={SelectorIcon1} />
                            <h2 className="Bonus-Selector-Text">Manage customer content</h2>
                        </div>
                        <div id="Bonus-Img-MB-1" className="Bonus-Image Image-Container" data-large="https://mavric.sirv.com/Real-Bonus-1.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648014/Bonus-1-Small_vllofc.webp"/>
                        </div>
                        <div className="Bonus-Selector" id="Bonus-Selector-MB-2" onClick={() => changeItem('Bonus-Selector-MB-2', 'Bonus-Img-MB-2')} >
                            <img className="Bonus-Selector-Icon" src={SelectorIcon2} />
                            <h2 className="Bonus-Selector-Text">Beautiful customer reviews</h2>
                        </div>
                        <div id="Bonus-Img-MB-2" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-2.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648044/Bonus-5-Small_ifq1qx.webp" />
                        </div>
                        <div className="Bonus-Selector" id="Bonus-Selector-MB-3" onClick={() => changeItem('Bonus-Selector-MB-3', 'Bonus-Img-MB-3')} >
                            <img className="Bonus-Selector-Icon" src={SelectorIcon3} />
                            <h2 className="Bonus-Selector-Text">Boost social proof</h2>
                        </div>
                        <div id="Bonus-Img-MB-3" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-5.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648029/Bonus-3-Small_o3zmd0.webp" />
                        </div>
                    </div>
                    <div className="Bonus-Selector-Wrap-Mobile">
                        <div className="Bonus-Selector" id="Bonus-Selector-MB-4" onClick={() => changeItem('Bonus-Selector-MB-4', 'Bonus-Img-MB-4')} >
                            <img className="Bonus-Selector-Icon " src={SelectorIcon1} />
                            <h2 className="Bonus-Selector-Text">Automated follow ups</h2>
                        </div>
                        <div id="Bonus-Img-MB-4" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-3.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648021/Bonus-2-Small_dpqfcw.webp" />
                        </div>
                        <div className="Bonus-Selector" id="Bonus-Selector-MB-5" onClick={() => changeItem('Bonus-Selector-MB-5', 'Bonus-Img-MB-5')} >
                            <img className="Bonus-Selector-Icon" src={SelectorIcon2} />
                            <h2 className="Bonus-Selector-Text">Incentivise content creation</h2>
                        </div>
                        <div id="Bonus-Img-MB-5" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-4.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648036/Bonus-4-Small_ca33ym.webp" />
                        </div>
                        <div className="Bonus-Selector No-Pointer" id="Bonus-Selector-MB-6">
                            <img className="Bonus-Selector-Icon" src={SelectorIcon3} />
                            <h2 className="Bonus-Selector-Text">Free lifetime access</h2>
                        </div>
                    </div>
                    <div id="Bonus-Img-Wrapper">
                        <div id="Bonus-Img-1" className="Bonus-Image Image-Container" data-large="https://mavric.sirv.com/Real-Bonus-1.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648014/Bonus-1-Small_vllofc.webp"/>
                        </div>
                        <div id="Bonus-Img-2" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-2.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648044/Bonus-5-Small_ifq1qx.webp" />
                        </div>
                        <div id="Bonus-Img-3" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-5.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648029/Bonus-3-Small_o3zmd0.webp" />
                        </div>
                        <div id="Bonus-Img-4" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-3.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648021/Bonus-2-Small_dpqfcw.webp" />
                        </div>
                        <div id="Bonus-Img-5" className="Bonus-Image Image-Container Hidden" data-large="https://mavric.sirv.com/Real-Bonus-4.webp">
                            <img  className="Placeholder-Img " src="https://res.cloudinary.com/dosucncg3/image/upload/v1700648036/Bonus-4-Small_ca33ym.webp" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="Full-Showcase Hide-Scrollbar" >
                <div id="Showcase-1"  style={ShowCaseTime} className="Image-Container Full-Img-Showcase" data-large={BonusShowcase}>
                    <img id="Pixel-Img" className="Placeholder-Img Full-Img-Showcase " src={BonusShowcaseSmall} />
                </div>                
                <div id="Showcase-2" style={ShowCaseTime} className="Image-Container Full-Img-Showcase" data-large={BonusShowcase}>
                    <img id="Pixel-Img" className="Placeholder-Img Full-Img-Showcase " src={BonusShowcaseSmall} />
                </div>                
            </div>


        </div>
    )
}

export default Bonus