import './IntroSection.css'
import SVGCross from './Cross.svg'
import WhiteHandArrow from './White-Hand-Arrow.svg'

import React from 'react'

const IntroSection = () => {

    const TopImgStyle1 = {
        '--time': '35s',
      };
    const TopImgStyle2 = {
        '--time2': '27s',
      };
    const TopImgStyle3 = {
        '--time3': '40s',
      };
    const TopBenefit1 = {
        '--time4': '20s',
      };
    const TopBenefit2 = {
        '--time5': '20s',
      };



  return (
    <div id="Intro-Section" style={{ backgroundImage: 'linear-gradient(rgb(18, 20, 29), rgb(23, 25, 36) 0%, rgb(18, 20, 29) 103%)'}}>

        <h2 id="Intro-Section-Title">Elevate your brand & boost sales with a store that's 🔥</h2>
        <div id="Intro-Section-Sub-Title-Wrap">
            <h2 id="Intro-Section-Sub-Title">Perfectly showcase your business</h2>
            <img id="Intro-Arrow" src={WhiteHandArrow} />
        </div>
        <div id="Top-Img-Wrapper">
            <div id="Top-Img-Container">
                <div id="Top-Img-Inner">
                    <div style={{display: 'flex', overflow:'hidden', flexDirection: 'column', width: 'calc(33% - 8px)', marginRight: '12px'}}>
                        <div className="Top-Img-Flex" id="Top-1" style={TopImgStyle1} >
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top1.png" 
                                data-med="https://mavric.sirv.com/Top%20Images/Top1.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top1.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top1Small.webp"/>
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top2.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top2.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top2.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top2Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top3.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top3.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top3.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top3Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top4.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top4.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top4.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top4Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top5.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top5.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top5.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top5Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top6.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top6.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top6.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top6Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top7.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top7.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top7.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top7Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top8.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top8.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top8.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top8Small.webp" />
                            </div>
                        </div>
                        <div className="Top-Img-Flex" id="Top-1-2" style={TopImgStyle1} >
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top1.png" 
                                data-med="https://mavric.sirv.com/Top%20Images/Top1.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top1.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top1Small.webp"/>
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top2.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top2.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top2.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top2Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top3.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top3.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top3.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top3Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top4.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top4.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top4.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top4Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top5.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top5.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top5.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top5Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top6.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top6.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top6.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top6Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top7.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top7.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top7.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top7Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images/Top8.png"
                                data-med="https://mavric.sirv.com/Top%20Images/Top8.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images/Top8.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images/Top8Small.webp" />
                            </div>
                        </div>

                        {/* <img className="Top-1" src={Top1} style={TopImgStyle1} />  */}
                    </div>
                    <div style={{display: 'flex', overflow:'hidden', flexDirection: 'column', width: 'calc(33% - 8px)',marginRight: '12px'}}>
                        {/* <img className="Top-2"  src={Top2} style={TopImgStyle2} />  */}
                        {/* <div className="Top-2 Image-Container" data-large={Top2} style={TopImgStyle2} >
                            <img className="Placeholder-Img" src={Top2Small}/>
                        </div> */}
                        <div className="Top-Img-Flex Top-2" id="Top-2-1" style={TopImgStyle2} >
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-1.png" 
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-1.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-1.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-1-Small.webp"/>
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-2.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-2.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-2.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-2-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-3.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-3.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-3.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-3-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-4.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-4.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-4.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-4-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-5.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-5.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-5.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-5-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-6.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-6.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-6.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-6-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-7.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-7.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-7.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-7-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-8.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-8.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-8.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-8-Small.webp" />
                            </div>
                        </div>
                        <div className="Top-Img-Flex Top-2" id="Top-2-2" style={TopImgStyle2} >
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-1.png" 
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-1.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-1.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-1-Small.webp"/>
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-2.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-2.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-2.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-2-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-3.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-3.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-3.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-3-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-4.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-4.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-4.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-4-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-5.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-5.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-5.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-5-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-6.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-6.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-6.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-6-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-7.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-7.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-7.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-7-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%202/Top2-8.png"
                                data-med="https://mavric.sirv.com/Top%20Images%202/Top2-8.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%202/Top2-8.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%202/Top2-8-Small.webp" />
                            </div>
                        </div>
                        
                        {/* <div className="Top-2 Image-Container" id="Top-2-2" data-large={Top2} style={TopImgStyle2} >
                            <img className="Placeholder-Img" src={Top2Small}/>
                        </div> */}
                        {/* <img className="Top-2" id="Top-2-2" src={Top2} style={TopImgStyle2} />  */}
                    </div>
                    <div style={{display: 'flex', overflow:'hidden', flexDirection: 'column', width: 'calc(33% - 8px)'}}>
                        {/* <img className="Top-3"  src={Top3} style={TopImgStyle3} />  */}
                        {/* <div className="Top-3 Image-Container" data-large={Top3} style={TopImgStyle3} >
                            <img className="Placeholder-Img" src={Top3Small}/>
                        </div> */}
                        <div className="Top-Img-Flex Top-3" id="Top-3-1" style={TopImgStyle3} >
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-1.png" 
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-1.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-1.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-1-Small.webp"/>
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-2.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-2.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-2.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-2-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-3.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-3.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-3.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-3-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-4.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-4.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-4.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-4-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-5.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-5.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-5.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-5-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-6.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-6.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-6.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-6-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-7.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-7.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-7.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-7-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-8.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-8.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-8.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-8-Small.webp" />
                            </div>
                        </div>
                        <div className="Top-Img-Flex Top-3" id="Top-3-2" style={TopImgStyle3} >
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-1.png" 
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-1.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-1.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-1-Small.webp"/>
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-2.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-2.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-2.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-2-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-3.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-3.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-3.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-3-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-4.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-4.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-4.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-4-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-5.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-5.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-5.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-5-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-6.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-6.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-6.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-6-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-7.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-7.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-7.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-7-Small.webp" />
                            </div>
                            <div className="Image-Container" 
                                data-large="https://mavric.sirv.com/Top%20Images%203/Top-3-8.png"
                                data-med="https://mavric.sirv.com/Top%20Images%203/Top-3-8.png?w=400"
                                data-small="https://mavric.sirv.com/Top%20Images%203/Top-3-8.png?w=250"
                            >
                                <img className="Placeholder-Img" src="https://mavric.sirv.com/Top%20Images%203/Top-3-8-Small.webp" />
                            </div>
                        </div>
                        {/* <div className="Top-3 Image-Container" id="Top-3-2" data-large={Top3} style={TopImgStyle3} >
                            <img className="Placeholder-Img" src={Top3Small}/>
                        </div> */}
                        {/* <img className="Top-3" id="Top-3-2" src={Top3} style={TopImgStyle3} />  */}
                    </div>
                </div>
                <div id="Top-Img-Gradient"></div>
                <div id="Top-Img-Gradient-2"></div>
            </div>
        </div>
        <div id="Intro-Section-No-Themes">

            <div className="IS-No-Themes-Inner">
                <img src={SVGCross} />
                <h2 className="IS-No-Themes-Text">No generic theme's used</h2>
            </div>
            <div className="IS-No-Themes-Inner">
                <img src={SVGCross} />
                <h2 className="IS-No-Themes-Text">No apps that slow down your store</h2>
            </div>

        </div>
    </div>
  )
}

export default IntroSection