import './CTA.css'
import Icon1 from './Icon1.svg'
import Icon2 from './Icon2.svg'
import Icon3 from './Icon3.svg'
import CTACircle from './CTACircle.svg'
import { useEffect } from 'react'
import { ScrollTo } from '../2 - Top Section/TopSection'

const CTA = () => {

    useEffect(() => {
        let scrollElement = document.getElementById('CTA-Card-Container');
        scrollElement.scrollLeft =  (scrollElement.scrollWidth - scrollElement.clientWidth ) / 2;
    }, []);

    return (
        <div id="CTA" >
            <img id="CTA-Circle" src={CTACircle} />
            <div id="CTA-Container">
                <h2 className="Headline CTA-Headline">Let’s take your business to the next level</h2>
                <h2 id="CTA-Subheadline">Choose from our affordable ‘done for you’ packages</h2>
            </div>
            <div id="CTA-Card-Container" className=" Hide-Scrollbar">
                <div className="CTA-Card First-Card">
                    <h2 className="CTA-Card-Type">Coding Package</h2>
                    <h2 className="CTA-2-Payments">2 instalments of</h2>
                    <h2 className="CTA-Price">$1,995</h2>
                    <h2 className="CTA-Whats-Included">What's included:</h2>
                    <h2 className="CTA-Included-Text">
                        <span className="Underlined Pointer" onClick={() => ScrollTo('Bonus', 0)}>
                            Free Social Influencer System
                        </span>
                        <br />
                        Entire Hand Coded Store
                        <br />
                        Zero Themes Used
                        <br />
                        Zero Apps Used
                        <br />3 Months Support
                    </h2>
                    <div className="CTA-Btm-Box">
                        <a href="https://buy.stripe.com/9AQeX12wXdsSawEeUY" target="_blank" className="CTA-Normal-Button">Get Started</a>
                        <a href="https://calendly.com/mavric/getstarted" target="_blank"  className='CTA-Call-Txt'>Book a call</a>
                    </div>      
                </div>
                <div className="CTA-Card Active-Card">
                    <div className="CTA-Card-Banner">
                        <h2 className="CTA-Card-Banner-Text">Recommended </h2>
                    </div>
                    <h2 className="CTA-Card-Type">Kickstarter Package</h2>
                    <h2 className="CTA-2-Payments">2 instalments of</h2>
                    <h2 className="CTA-Price">$2,495</h2>
                    <h2 className="CTA-Whats-Included">What's included:</h2>
                    <h2 className="CTA-Included-Text">
                        <span className="Underlined Pointer" onClick={() => ScrollTo('Bonus', 0)}>
                            Free Social Influencer System
                        </span>
                        <br />
                        Beautiful Designs For Every Device
                        <br />
                        Entire Hand Coded Store
                        <br />
                        Zero Themes Used
                        <br />
                        Zero Apps Used
                        <br />3 Months Support
                    </h2>
                    <div className="CTA-Btm-Box">
                        <a href="https://buy.stripe.com/28o3ej0oP2Oe8ow7sy" target="_blank" className="CTA-Active-Button">Get Started</a>
                        <a href="https://calendly.com/mavric/getstarted" target="_blank" className='CTA-Call-Txt'>Book a call</a>
                    </div>      
                </div>
                <div className="CTA-Card Last-Card">
                    <h2 className="CTA-Card-Type">All Inclusive Package</h2>
                    <h2 className="CTA-2-Payments">2 instalments of</h2>
                    <h2 className="CTA-Price">$3,995</h2>
                    <h2 className="CTA-Whats-Included">What's included:</h2>
                    <h2 className="CTA-Included-Text">
                        <span className="Underlined Pointer" onClick={() => ScrollTo('Bonus', 0)}>
                            Free Social Influencer System
                        </span>
                        <br />
                        Everything In Kickstarter Package
                        <br />
                        Entire Ad Campaign Strategy
                        <br />
                        Full Ad Campaign Roll-Out
                        <br />
                        Full Retargeting Roll-Out
                        <br />
                        6 Months Support
                    </h2>
                    <div className="CTA-Btm-Box">
                        <a href="https://buy.stripe.com/fZe16b1sT88y8ow6ot" target="_blank" className="CTA-Normal-Button">Get Started</a>
                        <a href="https://calendly.com/mavric/getstarted" target="_blank"  className='CTA-Call-Txt'>Book a call</a>
                    </div>                    
                </div>
                </div>

            <div className="Benefits-Grid CTA-Benefits">
                <div className="Benefits-Grid-Inner">
                    <img src={Icon1} />
                    <h2 className="Benefits-Grid-Headline">Refundable Deposit</h2>
                    <p className="Benefits-Grid-Text">Today's investment is a fully-refundable deposit that will cover the entire design and build-out of your store. </p>
                </div>
                <div className="Benefits-Divider"></div>
                <div className="Benefits-Grid-Inner">
                    <img src={Icon2} />
                    <h2 className="Benefits-Grid-Headline">2/3 Spaces Remain</h2>
                    <p className="Benefits-Grid-Text">Due to the hands-on nature of this process, I can only take on a few clients to ensure you recieve a top-tier store.</p>
                </div>
                <div className="Benefits-Divider"></div>
                <div className="Benefits-Grid-Inner">
                    <img src={Icon3} />
                    <h2 className="Benefits-Grid-Headline">Get Your Store Fast</h2>
                    <p className="Benefits-Grid-Text">Unlike other developers that waste time - we've perfected the process, so you will recieve your store in record time...</p>
                </div>
            </div>


            <div id="CTA-CTA-Container">
                <div id="CTA-CTA-Inner">
                    <h2 id="CTA-CTA-Text">Boost sales, increase AOV's & enhance your customers experience today</h2>
                    <div id="CTA-CTA-Inner-Wrap">
                        <a href="https://calendly.com/mavric/getstarted" target="_blank" id="CTA-CTA-Simple-Btn">Book A Call</a>
                        <div id="CTA-CTA-Btn"  onClick={() => {ScrollTo('CTA', -50)}}>Get Started Risk Free</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CTA