const SVGCircle = () => {
return (

<svg width="532" height="860" viewBox="0 0 532 860" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_f_564_1845)">
<circle cx="102" cy="430" r="76" fill="#FFCF73" fill-opacity="0.4"/>
</g>
<defs>
<filter id="filter0_f_564_1845" x="-328" y="0" width="860" height="860" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="177" result="effect1_foregroundBlur_564_1845"/>
</filter>
</defs>
</svg>
)
}

export default SVGCircle
