import React from 'react'
import '../16 - Terms Pop Up/Terms.css'
import CloseIcon from '../16 - Terms Pop Up/CloseIcon.svg'

const Refund = () => {

    const HideTerms = () => {
        document.getElementById('Refund-Popup').classList.add('Hidden')
    }
    
  return (
    <div id="Refund-Popup" className="Popup-Container Hidden">

        <div className="Popup-Content">
            <div className="Popup-Header">
                <h2 className="Popup-Title">Refund Policy</h2>
                <img id="Close-Icon" src={CloseIcon}  onClick={() => HideTerms()} />
            </div>
            <div className="Popup-Inner">
                <p className="Terms-Text">Your investment today is covered by a full returns policy, which works as described:
                <br />
                <br/>
                Before the work on your store begins, as we create the specification for your specific requirements - if you're not happy with the direction your store is going, and are not satisfied with the direction and plan we have created, you are entitled to your full refund.
                <br/>
                <br/>
                Once the work has begun on your store though, the refund policy is now void, and we will deliver the work agreed upon during the inital brainstorming/planning stage.
                </p>
            </div>
            <div className="Popup-Bottom" onClick={() => HideTerms()}>
                <div className="Disagree-Btn">
                    <h2 className="Disagree-Text">Disagree & Exit</h2>
                </div>
                <div className="Agree-Btn" onClick={() => HideTerms()}>
                    <h2 className="Agree-Text">Agree & Continue On Site</h2>
                </div>

            </div>
        </div>
        <div className="Popup-Background" onClick={() => HideTerms()}></div>
      
    </div>
  )
}

export default Refund;
