import './TopSection.css'
import '../1 - Top Menu/TopMenu.css'
import TopGradient from '../Images/TopGradient.svg'
import NewTop from './NewTop.svg'
import RightGradient from '../Images/RightGradient.svg'
import Video from '../Images/Video.png'
import Icon1 from './Icon1.svg'
import Icon2 from './Icon2.svg'
import Icon3 from './Icon3.svg'
import MavLogo from '../Mav-Logo.svg'
import Whatsapp from './Whatsapp.svg'
import { Crisp } from "crisp-sdk-web";
import TopImg from './Top-Img.png'
import SVGCircle from './SVGCircle'
import VidThumb from './Video-Thumb.webp'
import Top1 from './Top-1.png'
import Top1Small from './Top-1-Small.png'
import Top2 from './Top-2.png'
import Top2Small from './Top-2-Small.png'
import Top3 from './Top-3.png'
import Top3Small from './Top-3-Small.png'
import { useEffect } from 'react'

import Top11 from './Top1.png'
import Top22 from './Top2.png'
import Top33 from './Top3.png'
import Top4 from './Top4.png'
import Top5 from './Top5.png'
import Top6 from './Top6.png'
import Top7 from './Top7.png'
import Top8 from './Top8.png'

import Top11Small from './Top1Small.png'
import Top22Small from './Top2Small.png'
import Top33Small from './Top3Small.png'
import Top4Small from './Top4Small.png'
import Top5Small from './Top5Small.png'
import Top6Small from './Top6Small.png'
import Top7Small from './Top7Small.png'
import Top8Small from './Top8Small.png'




export const ShowLiveChat = () => {
    const chatContainer = document.getElementById('Live-Chat-Container');
    Crisp.load();
    Crisp.chat.hide();
    document.getElementById('Live-Chat-BG').classList.remove('Hidden')

    document.getElementById('Live-Chat-BG').addEventListener('click', (e) => {
        if (!chatContainer.contains(e.target)) {
            chatContainer.classList.remove('Show-Chat');
            Crisp.chat.hide();
            document.getElementById('Live-Chat-BG').classList.add('Hidden')

        }
    });
    setTimeout(() => {

        chatContainer.classList.add('Show-Chat');
    },0)
};

export const ScrollTo = (targetId, margin) => {
    const target = document.getElementById(targetId);
    if (target) {
      const topOffset = target.offsetTop - margin;
      window.scrollTo({
        top: topOffset,
        behavior: 'smooth'
      });
    }
}




const TopSection = () => {
    
    const TopImgStyle1 = {
        '--time': '35s',
      };
    const TopImgStyle2 = {
        '--time2': '27s',
      };
    const TopImgStyle3 = {
        '--time3': '40s',
      };
    const TopBenefit1 = {
        '--time4': '20s',
      };
    const TopBenefit2 = {
        '--time5': '20s',
      };

      const ShowHideFixedNav = () => {

       
            let lastScrollPosition = window.scrollY;
        
            window.addEventListener('scroll', function () {
                // Check if scrolled past 500px

                if (window.innerWidth > 905) {
                    if (window.scrollY < 300) {
                        document.getElementById('Top-Menu-Section').classList.remove('Sticky-Top-Gone');
                    } 
               
                    else if      
                    (window.scrollY > 1000) {

                        const currentScrollPosition = window.scrollY;

                        // Check scroll direction
                        if (currentScrollPosition > lastScrollPosition) {
                            // Scrolling down
                            document.getElementById('Top-Menu-Section').classList.add('Sticky-Top-Gone');
                        } else {
                            // Scrolling up
                        
                            console.log('HERE', window.scrollY)
                                document.getElementById('Top-Menu-Section').classList.remove('Sticky-Top-Gone');

                            
                        }

                     
            
                        // Update the last scroll position
                        lastScrollPosition = currentScrollPosition;

                    }


                    else {
                        document.getElementById('Top-Menu-Section').classList.add('Sticky-Top-Gone');

                    }
                }
                
            });
        
        

      }

    
      useEffect(() => {

  

            
            
            document.getElementById('Top-1').classList.add('Top-1-Animation')
            document.getElementById('Top-1-2').classList.add('Top-1-2-Animation')

            document.getElementById('Top-2-1').classList.add('Top-2-1-Animation')
            document.getElementById('Top-2-2').classList.add('Top-2-2-Animation')

            document.getElementById('Top-3-1').classList.add('Top-3-1-Animation')
            document.getElementById('Top-3-2').classList.add('Top-3-2-Animation')


            ShowHideFixedNav()

      },[])

    return (
        <div id="Top-Section">

                <div id="Top-Menu-Section">
                    <div className="Top-Menu-Container">
                        <div className="Logo-Menu-Container">
                            <img src={MavLogo} alt="Logo" id="Logo" />
                            <div className="Top-Menu-Text-Container">
                                <h2 className="Top-Menu-Text" onClick={() => {ScrollTo('Top-Section', 50)}} >Home</h2>
                                <h2 className="Top-Menu-Text" onClick={() => {ScrollTo('Blank-Canvas', 50)}}>Features</h2>
                                <h2 className="Top-Menu-Text" onClick={() => {ScrollTo('Speed', 0)}}>Speed Test</h2>
                                <h2 className="Top-Menu-Text" onClick={() => {ScrollTo('Bonus', 0)}}>Social Influencer</h2>
                                <h2 className="Top-Menu-Text" onClick={() => {ScrollTo('FAQs', 50)}}>FAQs</h2>
                            </div>
                        </div>
                        <div className="Top-Menu-Buttons-Container">
                            <div className="Top-Contact-Button" onClick={() => ShowLiveChat()}> Contact </div>
                            <div onClick={() => {ScrollTo('CTA', -50)}} className="Top-CTA-Button">
                                Get Started Risk Free
                            </div>
                        </div>
                    </div>
    
                </div>

            <img id="Top-Gradient" src={NewTop} />
            <img id="Right-Gradient" src={RightGradient} />
            <div id="Left-Gradient">
            <SVGCircle />
            </div> 

            <div id="Header-Wrapper">
                <h2 id="Header-Title">Forget generic Shopify theme's</h2>
                <h1 className="Main-Header-Title">Stand out from the crowd <span id="Line-Break"><br/></span>with a <span className="Header-Gradient">Custom Shopify Store</span></h1>
                <h1 className="Main-Header-Title-2">Stand out from <span id="Line-Break-3"><br/></span> the crowd <span id="Line-Break-2"><br/></span>with a <span className="Header-Gradient">Custom Shopify Store</span></h1>
            </div>
            <div id="Top-Benefits-Wrap" className='Hide-Scrollbar' style={TopBenefit1}>
                <div id="Top-Benefits-Container" >
                    <div className="Top-Benefits-Item">
                        <img className="Top-Benefits-Img" src={Icon1} />
                        <h2 className="Top-Benefits-Text">600%+ Faster Load Times</h2>
                    </div>
                    <div className="Top-Benefits-Item">
                        <img className="Top-Benefits-Img" src={Icon3} />
                        <h2 className="Top-Benefits-Text" >Beautiful Designs</h2>
                    </div>
                    <div className="Top-Benefits-Item">
                        <img className="Top-Benefits-Img" src={Icon2} />
                        <h2 className="Top-Benefits-Text">No Theme’s Or Apps Used!</h2>
                    </div>
                </div>
                <div id="Top-Benefits-Container-2" style={TopBenefit2}>
                    <div className="Top-Benefits-Item">
                        <img className="Top-Benefits-Img" src={Icon1} />
                        <h2 className="Top-Benefits-Text">600%+ Faster Load Times</h2>
                    </div>
                    <div className="Top-Benefits-Item">
                        <img className="Top-Benefits-Img" src={Icon3} />
                        <h2 className="Top-Benefits-Text">Beautiful Designs</h2>
                    </div>
                    <div className="Top-Benefits-Item">
                        <img className="Top-Benefits-Img" src={Icon2} />
                        <h2 className="Top-Benefits-Text">No Theme’s Or Apps Used!</h2>
                    </div>
                </div>
            </div>
            <div className="Canvas-Video Top-Video" id="">

                <div class="Wistia-Player" data-video-id="sapem137ws" data-wrapper-id="wistia-player-container"  data-video-thumb-id="Vid-Thumb" data-load-script-once="true" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb">
                    <div className='Wistia-Container' id="wistia-player-container"></div>
                    <div className="Vid-Loader-Container" id="Vid-Thumb" >
                        <div className="Vid-Container-Loader" id="Hide-Loader"></div>
                        <img className="Vid-Loader-Img" id="Hide-Img"src={VidThumb} />
                    </div>
                </div>
            </div>
                <h2 id="Header-Sub">Get a beautifuly designed Shopify store, complete with any custom functionality you require...</h2>
            <div id="Header-Button-Wrapper">
                <div onClick={() => ShowLiveChat()} className="CTA-Normal-Btn No-Outline Hover-Up">Book a call</div>
                <div onClick={() => {ScrollTo('CTA', -50)}} className="CTA-Button No-Outline">Get Started Risk Free</div>
            </div>



            <div id="Chat-Container" className="No-Outline" onClick={() => ShowLiveChat()}>
                <img id="Chat-Icon" src={Whatsapp} />
                <h2 id="Chat-Text">Let's chat</h2>
            </div>
        </div>
    )
}

export default TopSection