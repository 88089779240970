import './Proud.css'
import BulletTick from '../Images/BulletTick.svg'
import ProudFull from './Proud Full.png'
import ProudFullSmall from './Proud Full Small.png'
import { useEffect } from 'react'

const Proud = () => {

    function checkElementViewport(element) {
        document.addEventListener('scroll', function() {
 
            var start = element.getBoundingClientRect().top - window.innerHeight;
            var end = element.getBoundingClientRect().bottom;
            var distance = Math.abs(start - end);
            var percentageVisible = (start / distance) * -1;
            var percentage = Math.abs(percentageVisible);
            document.body.style.setProperty( "--proud-1", percentage )
          
        });
      }

      useEffect(() => {
        const element = document.getElementById('Proud-1');
        console.log(window.innerWidth)

        if (window.innerWidth > 950) {

            checkElementViewport(element)
        }
      }, []);

      const ProudTime = {
        '--proudTime': '35s',
      };




    return (
        <div id="Proud">
            <div id="Proud-Container">
                <div  id="Proud-Image"  className="Image-Container" data-large="https://mavric.sirv.com/Big-Proof.png">
                    <img id="Pixel-Img" className="Placeholder-Img" src="https://res.cloudinary.com/dosucncg3/image/upload/v1700647299/Proud-Small_ddeywu.webp" />
                </div>
                <div id="Proud-Inner">
                    <h2 className="Headline Proud-Headline">You’ll have a store that you’re proud of, zero compromises</h2>
                    {/* <p className="Text Proud-Text">
                        As we'll hand-code your store from zero, we'll build it to match your exact criteria - including stunning designs and any custom-functionality you need...
                    </p> */}
                    <div className="Bullet-Wrap">
                        <img src={BulletTick} />
                        <h2 className="Bullet-Text">Zero theme's or apps used!</h2>
                    </div>
                    <div className="Bullet-Wrap">
                        <img src={BulletTick} />
                        <h2 className="Bullet-Text">Full control over every aspect</h2>
                    </div>
                    <div className="Bullet-Wrap">
                        <img src={BulletTick} />
                        <h2 className="Bullet-Text">Positioned as the leader in your industry</h2>
                    </div>
                    <div className="Bullet-Wrap">
                        <img src={BulletTick} />
                        <h2 className="Bullet-Text">Boost conversions, AOV & enhance customer journey</h2>
                    </div>
                </div>
            </div>
            <div className="Full-Showcase Hide-Scrollbar" >
                <div id="Proud-1"  style={ProudTime} className="Image-Container Full-Img-Showcase" data-large={ProudFull}>
                    <img id="Pixel-Img" className="Placeholder-Img Full-Img-Showcase " src={ProudFullSmall} />
                </div>                
                <div id="Proud-2"  style={ProudTime} className="Image-Container Full-Img-Showcase" data-large={ProudFull}>
                    <img id="Pixel-Img" className="Placeholder-Img Full-Img-Showcase " src={ProudFullSmall} />
                </div>                
            </div>

        </div>
    )
}

export default Proud