import React, { useEffect } from 'react'
import './SpeedTest.css'

const SpeedTest = () => {

    function checkElementViewport(element) {
        document.addEventListener('scroll', function() {
 
            var start = element.getBoundingClientRect().top - window.innerHeight;
            var end = element.getBoundingClientRect().top - ((window.innerHeight / 100) * 20)
            console.log(start, end)
            var distance = Math.abs(start - end);
            var percentageVisible = (start / distance) * -1;
            var percentage = Math.abs(percentageVisible);
            document.body.style.setProperty( "--speed-showcase", percentage )
          
        });
      }

    function checkElementViewport2 (element) {
        document.addEventListener('scroll', function() {
 
            var start = element.getBoundingClientRect().top - window.innerHeight;
            var end = element.getBoundingClientRect().top - ((window.innerHeight / 100) * 60)
            console.log(start, end)
            var distance = Math.abs(start - end);
            var percentageVisible = (start / distance) * -1;
            var percentage = Math.abs(percentageVisible);
            document.body.style.setProperty( "--speed-inner", percentage )
          
        });
      }

      useEffect(() => {
        const element = document.getElementById('Speed-Test-Video');
        const element2 = document.getElementById('Speed-Inner');
        console.log(window.innerWidth)

        // if (window.innerWidth > 950) {
        //     checkElementViewport(element)
        //     checkElementViewport2(element2)
        // }
      }, []);

      const SpeedShowcaseTime = {
        '--speed-showcase-time': '35s',
      };



  return (
    <div id="Speed-Test">

        <h2 className="Headline Speed-Test-Headline">
        Outperform any other theme with lightening fast load times 🚀
        </h2>
        <div id="Speed-Test-Video" style={SpeedShowcaseTime}>
            <div class="Wistia-Player" data-video-id="hg9jzs9365" data-wrapper-id="wistia-player-container-88"   data-video-thumb-id="Vid-Thumb-88" data-hide-thumb="true" data-hide-thumb-id="Vid-Thumb-88" >
                <div className="Wistia-Container" id="wistia-player-container-88"></div>
                <div className="Vid-Loader-Container" id="Vid-Thumb-88" >
                    <div className="Vid-Container-Loader"></div>
                    <img className="Vid-Loader-Img" src="https://mavric.sirv.com/Vid-Thumby.png" />
                </div>
            </div>
        </div>
        <div id="Speed-Inner">

            <div id="Speed-Results-Wrap">
                <div className="Speed-Text-Wrap">
                    <h2 className="Speed-Result-Text">0.64s</h2>
                    <div className="Speed-Small-Text">Initial <br/> Load Time</div>
                </div>
                <h2 id="Speed-Vs-Text">vs</h2>
                <div className="Speed-Text-Wrap">
                    <h2 className="Speed-Result-Text Red-Text">6.7s</h2>
                    <div className="Speed-Small-Text">Average Load Time</div>
                </div>
            </div>
            <p className="Disclaimer-Text">* Based on a combined average of 100 of the top Shopify theme’s</p>
        </div>
    </div>
  )
}

export default SpeedTest